import React from "react";
import { Link } from "react-router-dom";
import {
  Heading,
  Flex,
  useColorMode,
  useBreakpointValue,
  Badge,
  Box
} from "@chakra-ui/react";


import { ColorModeSwitcher } from "./ColorModeSwitcher";

const Header = (props) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: "gray.500", dark: "gray.800" };
  const color = { light: "white", dark: "blue.200" };
  const showBadge = useBreakpointValue({ base: false, sm: true });

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      py="1rem"
      px="2rem"
      bg={bgColor[colorMode]}
      color={color[colorMode]}
      {...props}
    >
      <Box align="center" mr={5}>
        <Link to="/">
          <Heading fontSize={["1.125rem", null, null, "1.875rem", null, "2.25rem"]} as="h1">Fahrtkostenrechner.com{` `}
            {showBadge && <Badge ml="1" fontSize="0.5em" colorScheme="red">BETA</Badge>}
          </Heading>
        </Link>
      </Box>
      <ColorModeSwitcher justifySelf="flex-end" />

    </Flex>
  );
};

export default Header;
