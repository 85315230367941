import React from "react";

import {
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  Stack,
  useColorMode,
  Flex,
  Heading,
  Divider,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";

import { BiInfoCircle } from "react-icons/bi";

const Infobox = () => {
  // chakra ui handle for modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const color = { light: "gray.900", dark: "gray.200" };

  const drawerSize = useBreakpointValue({ base: "sm", lg: "md" })

  // bg={bgColor[colorMode]}
  // color={color[colorMode]}



  return (
    <>

      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h3" fontSize={["1rem", null, null, "1.5rem"]}>
          Fahrtkosten berechnen
        </Heading>
        {/* Drawer & Icon to toggle it   */}

        <IconButton
          variant="ghost"
          colorScheme="blue"
          aria-label="Ausfüllhilfe"
          fontSize="24px"
          icon={<BiInfoCircle />}
          name="check-circle"
          isRound="true"
          onClick={onOpen}
        />
        <Drawer placement="right" onClose={onClose} isOpen={isOpen} size={drawerSize}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader
              borderBottomWidth="1px"
              borderColor={color[colorMode]}
              color={color[colorMode]}
            >
              Tipps &amp; Hinweise zur Nutzung
          </DrawerHeader>
            <DrawerBody color={color[colorMode]}>
              <Stack spacing={8} mt={4}>
                <Text>
                  Zur einfachen Berechnung und Ermittlung der Fahrtkosten sind Angaben zur Strecke, der Verbrauch sowie der derzeit aktuelle Spritpreis
                  anzugeben.
              </Text>

                <Text
                  borderLeftWidth="3px"
                  pl={4}
                  borderLeftColor="blue.200"
                  fontWeight="normal"
                >
                  <em>
                    <b>Tipp:</b> Klicken Sie im Startfeld auf das Symbol rechts,
                  um Ihren Standort automatisch ermitteln zu lassen.
                </em>
                </Text>

                <Text>
                  Die optionalen Angaben zu mitfahrenden Personen, Rückfahrt oder Sonstigen Kosten sind im Rechner frei wählbar. Der
                  Fahrtkostenrechner ist immer auf einfache Fahrt bzw. auf eine Person voreingestellt.
              </Text>

                <Text>
                  Sind alle Angaben vorhanden, klicken Sie auf den Button "Kosten berechnen", um Ihre tätsächlichen Fahrt- und Reisekosten zu erhalten.
              </Text>

                <Text>
                  Aktuelle Durchschnittspreise für Kraftstoff: Super E10: 135,1 Cent; Diesel: 123,3 Cent (Stand: 01/2021; Quelle: ADAC)
              </Text>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

      </Flex>

      <Divider />



    </>
  );
};

export default Infobox;
