import React from "react";
import { Box, Container, Flex, Heading } from "@chakra-ui/react";

export default function Impressum() {

  // const { colorMode } = useColorMode();
  // const color = { light: "gray.900", dark: "gray.300" };

  return (
    <Flex
      wrap="wrap"
      align="stretch"
      grow="1"
      shrink="0"
      basis="auto"
    // color={color[colorMode]}
    >

      <Container maxW={["full", null, "container.lg", null]} px={[4, null, 0, null]} py={8} centerContent>
        <Box as="main" padding="1" w="100%">

          <Heading as="h1">Impressum</Heading>

          <p><strong>Angaben gemäß § 5 TMG:</strong></p>
          <p>Rafael Cabrera Striberny<br />
          Obertorstr. 46<br />
          73728 Esslingen</p>
          <p>Telefon: +49 (0)160 920 68 456<br />
          E-Mail: info@bits-und-meer.de<br />
            <em>Bitte nennen Sie in jeder Mail den Namen der Website um die es geht.</em></p>
          <p>Umsatzsteuer-Identifikationsnummer: DE275461297</p>
          <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
          <p>Rafael Cabrera Striberny<br />
          Obertorstr. 46<br />
          73728 Esslingen</p>
          <h3>Streitschlichtung</h3>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </Box>
      </Container>

    </Flex>

  );
}