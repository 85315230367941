// example theme.js
// export default {
//   fonts: {
//     body: "Poppins, sans-serif",
//   },
// };

import WebFont from "webfontloader";
import { extendTheme } from "@chakra-ui/react";
// import { createBreakpoints } from "@chakra-ui/theme-tools"
// import "./fonts.css"
// const breakpoints = createBreakpoints({
//   sm: "30em",
//   md: "48em",
//   lg: "62em",
//   xl: "80em",
// })

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}


// Let's say you want to add custom colors
const customTheme = extendTheme({
  // breakpoints,
  config,
  fonts: {
    heading: "Poppins, sans-serif",
    body: "Poppins, sans-serif",
    mono: "Poppins, sans-serif",
  },
  fontWeights: {
    normal: 300,
    medium: 500,
    bold: 700,
  },
  textStyles: {
    italic: {
      fontStyle: "italic",
      fontFamily: "'Caveat', cursive",
    },
  },
  styles: {
    global: {
      "html, body": {
        height: "100%"
      },
      "body.chakra-ui-light": {
        backgroundColor: "gray.50"
      },
      "body.chakra-ui-dark": {
        backgroundColor: "gray.900"
      },

      "#root": {
        display: "flex",
        flexDirection: "column",
        height: "100%"
      },
      "main h1": {
        marginBottom: 8
      },
      "main p": {
        marginBottom: 4
      }
    },
  },
});

export default customTheme;