import { Box, Flex } from "@chakra-ui/react"
import React from "react"

import { FacebookShareButton, TwitterShareButton, TwitterIcon, FacebookIcon } from "react-share"

const SocialIcons = () => {

  return (

    <Flex key="social-buttons" sx={{ justifyContent: `center` }}>
      <Box sx={{ p: 2 }}>
        <FacebookShareButton url="https://fahrtkostenrechner.com" quote="Fahrtkosten online berechnen">
          <FacebookIcon
            size={32}
            round
          />
        </FacebookShareButton>
      </Box>

      <Box sx={{ p: 2 }}>
        <TwitterShareButton title="Linktipp: Fahrtkosten online berechnen (inkl. Streckenberechnung mit Zwischenzielen und vieler weiterer Features)" url="https://fahrtkostenrechner.com">
          <TwitterIcon
            size={32}
            round
          />
        </TwitterShareButton>
      </Box>
    </Flex>
  )
}

export default SocialIcons