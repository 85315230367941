// export const formatWaypoints = (waypoints) => {

//   const formattedWaypoints = []

//   waypoints.map( (waypoint) => {
//     formattedWaypoints.push( {location: waypoint, stopover: false } )
//   })

//   return formattedWaypoints
// }

export const meterToKm = (distanceInM) => {
  let km = (distanceInM / 1000).toFixed(2).replace(".", ",");
  return `${km} km`;
};

export const secondsToHours = (seconds) => {
  let s = Number(seconds);
  let h = Math.floor(s / 3600);
  let m = Math.floor((s % 3600) / 60);

  let hDisplay = h > 0 ? h + (h === 1 ? " Stunde, " : " Stunden, ") : "";
  let mDisplay = m > 0 ? m + (m === 1 ? " Minute, " : " Minuten ") : "";
  return hDisplay + mDisplay;

  // return new Date(seconds * 1000).toISOString().substr(11, 5);
};
