import React from "react";

import { Text, Flex, Box } from "@chakra-ui/react";
import { GiPathDistance, GiTimeBomb } from "react-icons/gi";

import { meterToKm, secondsToHours } from "../../utils/mapUtils";

const RouteInfo = ({ distance, duration }) => {
  // const { colorMode } = useColorMode();
  // const bgColor = { light: "gray.500", dark: "gray.800" };
  // const color = { light: "white", dark: "gray.400" };

  return (
    <Flex
      borderRadius="md"
      p={2}
      zIndex="1"
      pos="absolute"
      top="1rem"
      left="1rem"
      size="sm"
      bg="white"
      opacity="0.9"
      align="center"
      h="48px"
      w="auto"
      color="gray.500"
    >
      <Box m={2} boxSize="32px" as={GiPathDistance} />
      <Text fontSize="xl" fontWeight="700">
        {meterToKm(distance)}
      </Text>

      <Box m={2} boxSize="32px" as={GiTimeBomb} />
      <Text fontSize="xl" fontWeight="700">
        {duration ? secondsToHours(duration) : "0h 0m"}
      </Text>
    </Flex>
  );
};

export default RouteInfo;
