import React, { useState, useEffect } from "react";

import {
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Divider,
  Stack,
  ButtonGroup,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Tooltip,
  Icon,
  InputRightElement,
  InputGroup
} from "@chakra-ui/react";

import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import {
  routeResultState,
  directionsState,
  renderDirectionsState,
  routeDataState,
  formattedWptsState,
} from "../../recoil";


import Directions from "../Directions";
import consoleHelper from "../../utils/consoleHelper"


const Form = () => {
  // recoil (global) state
  const [routeResult, setRouteResult] = useRecoilState(routeResultState);
  const setDirections = useSetRecoilState(directionsState);
  const setRenderDirections = useSetRecoilState(renderDirectionsState);
  const [routeData, setRouteData] = useRecoilState(routeDataState);

  const initialState = {
    origin: "",
    destination: "",
    waypoints: [{ value: "", label: "Ziel" }],
  };

  // "Helper" (local) state
  const [origin, setOrigin] = useState(initialState.origin);
  const [destination, setDestination] = useState(initialState.destination);
  const [waypoints, setWaypoints] = useState(initialState.waypoints);

  const resetRouteDataState = useResetRecoilState(routeDataState);
  const resetDirectionsState = useResetRecoilState(directionsState);
  const resetRenderDirectionsState = useResetRecoilState(renderDirectionsState);
  const resetRouteResultState = useResetRecoilState(routeResultState);
  const resetFormattedWptsState = useResetRecoilState(formattedWptsState);

  const resetForm = () => {
    return (
      resetRouteDataState(),
      resetDirectionsState(),
      resetRenderDirectionsState(),
      resetRouteResultState(),
      resetFormattedWptsState(),
      setOrigin(initialState.origin),
      setDestination(initialState.destination),
      setWaypoints(initialState.waypoints)
    );
  };

  const toast = useToast();

  // Just for testing, fires everytime some parameter in result changes
  // useEffect(() => {
  //   consoleHelper(routeResult)
  // }, [routeResult]);

  // calculate cost
  useEffect(() => {
    if (
      routeData.gasPrice &&
      routeData.consumption &&
      routeResult.distance !== 0
    ) {

      consoleHelper(`Benzinpreis: ${routeData.gasPrice}, Verbrauch: ${routeData.consumption}, Entfernung: ${routeResult.distance}`);


      let gasPrice = routeData.gasPrice.replace(",", ".");
      let consumption = routeData.consumption.replace(",", ".");
      let otherCosts = routeData.otherCosts;
      otherCosts = parseFloat(routeData.otherCosts.replace(",", ".")) | 0;

      // / 1000 for km in m and / 100 for consumption per 100 km
      let gasolineCost =
        (routeResult.distance / 1000 / 100) *
        parseFloat(consumption) *
        parseFloat(gasPrice);

      gasolineCost = routeData.roundTrip ? gasolineCost * 2 : gasolineCost;

      setRouteResult((routeResult) => {
        return {
          ...routeResult,
          gasolineCost,
          otherCosts,
        };
      });
    }
  }, [
    routeResult.distance,
    routeData,
    setRouteResult
  ]);

  // handle all text fields
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    name === "consumption" &&
      setRouteData((routeData) => {
        return {
          ...routeData,
          consumption: value,
        };
      });
    name === "gas_price" &&
      setRouteData((routeData) => {
        return { ...routeData, gasPrice: value };
      });
    name === "other_costs" &&
      setRouteData((routeData) => {
        return { ...routeData, otherCosts: value };
      });
    name === "round_trip" &&
      setRouteData((routeData) => {
        return { ...routeData, roundTrip: value };
      });
    // name === 'passengers' && setPassengers(value)
    consoleHelper(routeData);
  };

  // when user clicks on submit button or hits enter
  const handleSubmit = (e) => {
    e.preventDefault();

    if (origin !== "" && destination !== "") {
      setDirections((directions) => {
        return {
          ...directions,
          origin: origin,
          destination: destination,
        };
      });
      setRenderDirections((renderDirections) => {
        return { ...renderDirections, renderDirections: true };
      });
      consoleHelper(waypoints)
    } else {
      toast({
        position: "bottom-right",
        title: "Fehler.",
        description: "Bitte checken Sie Ihre Eingaben.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      consoleHelper("Bitte Start und Ziel eingeben.");
    }
  };

  return (
    <>


      <form onSubmit={handleSubmit}>
        <Stack mt={4} spacing={4}>
          <Directions
            origin={origin}
            setOrigin={setOrigin}
            setDestination={setDestination}
            waypoints={waypoints}
            setWaypoints={setWaypoints}
          />


          <Divider />

          <FormControl>
            <FormLabel htmlFor="consumption">Verbrauch / 100 km</FormLabel>
            <InputGroup>
              <Input
                name="consumption"
                type="text"
                placeholder="7,5"
                value={routeData.consumption}
                onChange={(e) => handleInputChange(e)}
              ></Input>
              <InputRightElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children="Liter"
                width="5rem"
                justifyContent="flex-end"
                paddingRight="1rem"
                textAlign="right"
                textStyle="italic"
              />
            </InputGroup>
          </FormControl>

          {/* <Slider aria-label="slider-ex-4" onChange={(val) => { consoleHelper(val / 10 + 3) }}>
            <SliderTrack bg="red.100">
              <SliderFilledTrack bg="tomato" />
            </SliderTrack>
            <SliderThumb boxSize={6}>
              <Box color="tomato" />
            </SliderThumb>
          </Slider> */}

          <FormControl>
            <FormLabel htmlFor="gas_price">Benzinpreis</FormLabel>
            <InputGroup>
              <Input
                name="gas_price"
                type="text"
                placeholder="1,30"
                value={routeData.gasPrice}
                onChange={(e) => handleInputChange(e)}
              ></Input>
              <InputRightElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children="Euro"
                width="5rem"
                justifyContent="flex-end"
                paddingRight="1rem"
                textAlign="right"
                textStyle="italic"
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="other_costs">
              <Tooltip label="z. B. Maut, Verschleiss, etc." placement="bottom" aria-label="Erläuterungen sonstige Kosten" textStyle="italic">
                <Icon mr={1} name="info-outline" />
              </Tooltip>
              Sonstige Kosten
            </FormLabel>
            <InputGroup>
              <Input
                name="other_costs"
                type="text"
                placeholder="0"
                value={routeData.otherCosts}
                onChange={(e) => handleInputChange(e)}
              ></Input>
              <InputRightElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children="Euro"
                width="5rem"
                justifyContent="flex-end"
                paddingRight="1rem"
                textAlign="right"
                textStyle="italic"
              />
            </InputGroup>
          </FormControl>

          <Flex alignItems="flex-end">

            <FormControl>
              <FormLabel htmlFor="persons">Anzahl Personen</FormLabel>
              <NumberInput
                name="persons"
                maxW="50%"
                min="1"
                max="6"
                value={routeData.persons}
                onChange={(persons) => {

                  if (parseInt(persons) !== routeData.persons) {
                    setRouteData((routeData) => {
                      return {
                        ...routeData,
                        persons: parseInt(persons),
                      };
                    })
                  }
                }
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>

            <FormControl as={Flex} alignItems="center">
              <Switch
                mr={2}
                color="blue"
                size="lg"
                name="round_trip"
                isChecked={routeData.roundTrip}
                onChange={handleInputChange}
              />
              <FormLabel>Rückfahrt?</FormLabel>
            </FormControl>

          </Flex>


          <ButtonGroup spacing={4}>
            <Button mt={8} colorScheme="green" type="submit">
              Kosten berechnen
            </Button>
            <Button mt={8}
              colorScheme="gray"
              variant="outline"
              type="reset"
              onClick={resetForm}
            >
              Zurücksetzen
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </>
  );
};

export default Form;
