import React, { StrictMode } from "react";
import { RecoilRoot } from "recoil";

import { render } from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";

// import { Global, css } from "@emotion/react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import customTheme from "./theme";
import Header from "./components/Header";
import Footer from "./components/Footer/";
import Homepage from "./pages/Homepage";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import NotFound from "./pages/404";
// import Autocomplete from "./pages/autocomplete"

const rootElement = document.getElementById("root");

render(
  <StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <ChakraProvider theme={customTheme}>
          <Header />
          <Routes>
            {/* <Route path="/Autocomplete" element={<Autocomplete />} /> */}
            <Homepage path="/" />
            <Impressum path="/impressum" />
            <Datenschutz path="/datenschutz" />
            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>
          <Footer />
        </ChakraProvider>
      </RecoilRoot>
    </BrowserRouter>
  </StrictMode>,

  rootElement
);
