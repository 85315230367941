import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
  useColorMode,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import CountUp from 'react-countup';
import SocialIcons from "../SocialIcons"

// user
import { routeResultState } from "../../recoil";
import { germanNumberFormat } from "../../utils/formattingUtils";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const Result = ({ persons }) => {
  const result = useRecoilValue(routeResultState);

  const [gasolineCostState, setGasolineCostState] = useState(0)

  const { colorMode } = useColorMode();
  const color = { light: "gray.500", dark: "blue.200" };

  // we need to know this to attach the scroller to the result box
  let isPageWide = useMediaQuery('(min-width: 992px)')


  // useful when viewing on mobile devices, where result box isn't above the fold
  const resultBox = useRef();
  const firstRender = useRef(true);

  // we need the pre rendered data for CountUp component start
  const currentCasolineCost = useRef(gasolineCostState);

  useEffect(() => {
    // check if it's the first render, if yes, do nothing but if not that means that a calculation has been made so smoothly scrollIntoView
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setGasolineCostState(() => result.gasolineCost)

    !isPageWide && resultBox.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [result, isPageWide])

  // consoleHelper(germanNumberFormat(result.gasolineCost, false));


  return (
    <Box p="6">
      <Flex justify="space-between" wrap="wrap">
        <Box width={["100%", "50%", "25%", null]} textAlign="center" my={4}>
          <Stat>
            <StatLabel
              fontSize={["sm", "md", null, null]}
              color={color[colorMode]}
            >
              Spritkosten
            </StatLabel>
            <StatNumber fontSize={["3xl", null, null, null, "4xl"]}>
              <CountUp start={currentCasolineCost.current} decimal="," decimals={2} end={result.gasolineCost} />{" "}€
              {/* {germanNumberFormat(result.gasolineCost, false)} */}
            </StatNumber>
            <StatHelpText>
              {germanNumberFormat(result.gasolineCost / persons)} / Person{" "}
            </StatHelpText>
          </Stat>
        </Box>
        <Box width={["100%", "50%", "25%", "25%"]} textAlign="center" my={4}>
          <Stat>
            <StatLabel
              fontSize={["sm", "md", null, null]}
              color={color[colorMode]}
            >
              Sonstige Kosten
            </StatLabel>
            <StatNumber fontSize={["3xl", null, null, null, "4xl"]}>
              {germanNumberFormat(result.otherCosts)}
            </StatNumber>
            <StatHelpText>
              {" "}
              {germanNumberFormat(result.otherCosts / persons)} / Person
            </StatHelpText>
          </Stat>
        </Box>
        <Box width={["100%", "50%", "25%", null]} textAlign="center" my={4}>
          <Stat>
            <StatLabel
              fontSize={["sm", "md", null, null]}
              color={color[colorMode]}
            >
              Gesamtkosten...
            </StatLabel>
            <StatNumber fontSize={["3xl", null, null, null, "4xl"]}>
              <CountUp start={0} decimal="," decimals={2} end={result.otherCosts + result.gasolineCost} />{" "}€
            </StatNumber>
            <StatHelpText></StatHelpText>
          </Stat>
        </Box>
        <Box ref={resultBox} width={["100%", "50%", "25%", null]} textAlign="center" my={4}>
          <Stat>
            <StatLabel
              fontSize={["sm", "md", null, null]}
              color={color[colorMode]}
            >
              ...pro Person
            </StatLabel>
            <StatNumber fontSize={["3xl", null, null, null, "4xl"]}>
              <CountUp start={0} decimal="," decimals={2} end={(result.otherCosts + result.gasolineCost) / persons} />{" "}€
            </StatNumber>
            <StatHelpText>
              {persons} {persons === 1 ? "Person" : "Personen"}
            </StatHelpText>
          </Stat>
        </Box>
      </Flex>

      <Box textAlign="center">
        <Text fontSize="sm" color="gray.400">
          Die Berechnungen von Fahrtkosten und/oder Spritkosten mit dem
          Spritkostenrechner sind nicht zwingend bindend und dienen rein Ihrer
          privaten Information.
        </Text>
        <SocialIcons />
      </Box>
    </Box>
  );
};

export default Result;
