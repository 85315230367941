import { atom } from "recoil";

export const directionsState = atom({
  key: "directionsState",
  default: {
    origin: "",
    destination: "",
  },
});

export const formattedWptsState = atom({
  key: "formattedWptsState",
  default: [
    {
      value: "",
      label: "Ziel",
    },
  ],
});

export const renderDirectionsState = atom({
  key: "renderDirectionsState",
  default: {
    renderDirections: false,
    response: null,
  },
});

export const routeDataState = atom({
  key: "routeDataState",
  default: {
    consumption: "",
    gasPrice: "",
    roundTrip: false,
    persons: 1,
    otherCosts: "",
  },
});

export const routeResultState = atom({
  key: "routeResultState",
  default: {
    distance: 0,
    duration: 0,
    gasolineCost: 0,
    otherCosts: 0,
  },
});
