import React from "react";

import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";

import { useRecoilState, useRecoilValue } from "recoil";

import RouteInfo from "../RouteInfo";
import consoleHelper from "../../utils/consoleHelper";

import {
  directionsState,
  formattedWptsState,
  renderDirectionsState,
  routeResultState,
} from "../../recoil";

// import
//   usePlacesAutocomplete,
//   {
//     getGeocode,
//     getLatLng,
//   }
//   from "use-places-autocomplete";

import { Box } from "@chakra-ui/react";

import mapStyes from "./mapStyles";

// center map at this location (center of Germany)
const center = {
  lat: 51.58799,
  lng: 10.12307,
};

// which libraries to lad
const libraries = ["places"];

// css for map
const mapContainerStyle = {
  width: "100%",
  height: "60vh",
  position: "relative",
};

// options for the map
const options = {
  styles: mapStyes,
  disableDefaultUI: true,
  zoomControl: true,
};

// options for directons service
const travelMode = "DRIVING";

const Map = ({ response, setResponse }) => {
  const [renderDirections, setRenderDirections] = useRecoilState(
    renderDirectionsState
  );

  // only reading, not writing
  const directions = useRecoilValue(directionsState);
  const formattedWpts = useRecoilValue(formattedWptsState);
  const [routeResult, setRouteResult] = useRecoilState(routeResultState);

  // load map script and libraries
  const { isLoaded, isError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (isError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  // callback for <DirectionsService />
  const directionsCallback = (res) => {
    if (res != null) {
      if (res.status === "OK") {
        setRenderDirections((renderDirections) => {
          return { ...renderDirections, response: res };
        });
        // retrieve distance and duration for route and save it

        let legs = [];
        let totalDistance = null;
        let totalDuration = null;

        legs = res.routes[0].legs;

        if (legs.length > 1) {
          totalDistance = legs.reduce(function (acc, curr) {
            return acc + curr.distance.value;
          }, 0);
          totalDuration = legs.reduce(function (acc, curr) {
            return acc + curr.duration.value;
          }, 0);
        } else {
          totalDistance = legs[0].distance.value;
          totalDuration = legs[0].duration.value;
        }

        if (totalDistance && totalDuration) {
          setRouteResult((routeResult) => {
            return {
              ...routeResult,
              distance: totalDistance,
            };
          });

          setRouteResult((routeResult) => {
            return {
              ...routeResult,
              duration: totalDuration,
            };
          });
        }

        setRenderDirections((renderDirections) => {
          return { ...renderDirections, renderDirections: false };
        });
      } else {
        consoleHelper(`response: , ${response}`);
      }
    }
  };

  return (
    <Box pos="relative">
      <RouteInfo
        distance={routeResult.distance}
        duration={routeResult.duration}
      />

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={6}
        center={center}
        options={options}
        onClick={(event) => {}}
      >
        {directions.origin !== "" &&
          directions.destination !== "" &&
          renderDirections.renderDirections && (
            <DirectionsService
              options={{
                origin: directions.origin,
                destination: directions.destination,
                waypoints: formattedWpts,
                optimizeWaypoints: true,
                travelMode,
              }}
              callback={directionsCallback}
            />
          )}

        {renderDirections.response !== null && (
          <DirectionsRenderer
            options={{
              directions: renderDirections.response,
            }}
          />
        )}
      </GoogleMap>
    </Box>
  );
};

export const MemoizedMap = React.memo(Map);
