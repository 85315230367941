import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Box, Flex, useColorMode } from "@chakra-ui/react";

import Form from "../components/Form";
import FormHeader from "../components/FormHeader";
import { MemoizedMap } from "../components/Map";
import Result from "../components/Result";

import { useRecoilValue } from "recoil";

import { routeDataState } from "../recoil";
import consoleHelper from "../utils/consoleHelper"

export default function Homepage() {
  const routeData = useRecoilValue(routeDataState);

  const { colorMode } = useColorMode();
  const formBgColor = { light: "gray.50", dark: "gray.600" };
  const resultBgColor = { light: "gray.100", dark: "gray.700" };

  const color = { light: "gray.900", dark: "gray.300" };

  // bg={bgColor[colorMode]}
  // color={color[colorMode]}

  consoleHelper('rendering...')

  return (
    <Flex
      wrap="wrap"
      align="stretch"
      grow="1"
      shrink="0"
      basis="auto"
      color={color[colorMode]}
    >
      <HelmetProvider>

        <Helmet>
          <title>Fahrtkosten berechnen | Fahrtkostenrechner.com</title>
          <meta name="description"
            content="Online Fahrtkostenrechner 2021 mit ✅ automatischer Streckenberechnung und vielen weiteren Features ➽ Berechnen Sie hier Ihre Fahrtkosten."></meta>
          <link
            rel="canonical"
            href="https://fahrtkostenrechner.com"
          />
        </Helmet>
      </HelmetProvider>

      <title>Fahrtkostenrechner - Fahrtkosten online berechnen</title>

      <Box
        width={[
          "100%", // base
          null, // 480px upwards
          null, // 768px upwards
          1 / 3, // 992px upwards
          null, // 1280px upwards
          1 / 4, // 1280px upwards
        ]}
        padding={8}
        bg={formBgColor[colorMode]}
      >
        <FormHeader />
        <Form />
      </Box>

      <Box w={["100%", null, null, 2 / 3, null, 3 / 4]} bg={resultBgColor[colorMode]}>
        <Box>
          <MemoizedMap />
        </Box>
        <Result persons={routeData.persons} />
      </Box>
    </Flex>
  );
}
