import React from "react";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";

export default function NotFound() {

  // const { colorMode } = useColorMode();
  // const color = { light: "gray.900", dark: "gray.300" };

  return (
    <Flex
      wrap="wrap"
      align="stretch"
      grow="1"
      shrink="0"
      basis="auto"
    // color={color[colorMode]}
    >

      <Container maxW={["full", null, "container.lg", null]} px={[4, null, 0, null]} py={8} centerContent>
        <Box as="main" padding="1" w="100%">
          <Heading as="h1">Fehler 404</Heading>
          <Text>Seite nicht gefunden.</Text>
        </Box>
      </Container>

    </Flex>

  );
}