import React from "react";
import { Box, Text, useColorMode, Link } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

const Footer = () => {
  const { colorMode } = useColorMode();
  const bgColor = { light: "gray.500", dark: "gray.800" };
  const color = { light: "white", dark: "gray.400" };

  return (
    <Box
      p={8}
      bg={bgColor[colorMode]}
      color={color[colorMode]}
      textAlign="center"
      flexShrink="0"
    >
      <Text fontSize="sm">
        © 2021 <Link as={ReactRouterLink} to="/">Fahrtkostenrechner.com</Link > - Berechnung von Benzin, Sprit- und
        Fahrtkosten -{" "}
        <Link color={color[colorMode]} href="/impressum">
          Impressum
        </Link>{" "}
        -{" "}
        <Link color={color[colorMode]} href="/datenschutz">
          Datenschutz
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
