import React, { useState, useEffect, useRef } from "react";
// import { useQuery } from 'react-query'

import {
  Input,
  FormControl,
  FormLabel,
  IconButton,
  ButtonGroup,
  InputGroup,
  InputRightElement,
  Spinner,
  InputLeftElement
} from "@chakra-ui/react";

import { BiPlus, BiMinus, BiCurrentLocation } from "react-icons/bi";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { formattedWptsState, renderDirectionsState } from "../../recoil";

import consoleHelper from "../../utils/consoleHelper"

const Directions = ({
  origin,
  setOrigin,
  setDestination,
  waypoints,
  setWaypoints,
}) => {
  // recoil (global) state
  // only reading, not writing
  const renderDirections = useRecoilValue(renderDirectionsState);

  // only writing, not reading
  const setFormattedWpts = useSetRecoilState(formattedWptsState);

  // mutable ref object which persists for the full lifetime of this component
  const firstRender = useRef(true);

  const [geolocation, setGeolocation] = useState(false);
  const [fetchCityLoading, setFetchCityLoading] = useState(false);

  // run this either
  // - on render
  // - if renderDirections change (click on submit)
  // - if waypoints are changed, added or removed

  // const initialCityFetchState = {
  //   loading: false,
  //   error: null,
  //   city: ''
  // }

  // function cityFetchReducer(state, action) {

  //   switch (action.type) {
  //     case `loading`:
  //       return { loading: true };
  //     case `error`:
  //       return { loading: false, r}
  //   }

  // }

  useEffect(() => {
    // skip this effect on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // get the waypoint labeled with "Ziel" as it's our destination
    const dest = waypoints.filter((waypoint) => waypoint.label === "Ziel");

    // get rid of the label, we jut want the value
    dest.forEach((dest, index, arr) => {
      arr[index] = dest.value;
    });

    // this makes only sense, if there is at least one waypoint (= destination)
    setDestination(dest.toString());

    // more than one waypoints?
    const wpts = waypoints.filter(
      (waypoint) => waypoint.label === "Zwischenziel"
    );

    wpts.forEach((wpt, index, arr) => {
      arr[index] = { location: wpt.value, stopover: true };
    });

    setFormattedWpts(wpts);
  }, [waypoints, renderDirections, setFormattedWpts, setDestination]);

  // get user geo location

  useEffect(() => {
    if ("geolocation" in navigator) {
      setGeolocation(true);
    } else {
      consoleHelper('Geolocation not available')
    }
    consoleHelper('useEffect for geolocation fired, rerendering..')
  }, []);

  // const handleError = function (err) {
  //   console.warn(err);
  //   return new Response(
  //     JSON.stringify({
  //       code: 400,
  //       message: "Netzwerkfehler",
  //     })
  //   );
  // };

  async function getCityName(position) {
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    consoleHelper("Standort wird ermittelt..")

    // const { isLoading, error, data } = useQuery('cityName', () =>
    //   fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${api_key}`)
    //     .then(res => res.json())
    // )

    const location = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${api_key}`
    )
      .then((response) => {
        if (response.ok) {
          consoleHelper("Standort erfolgreich ermittelt..");
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .catch((error) => {
        setFetchCityLoading(false);
        console.error(error + ": Konnte Ort nicht ermitteln");
      });


    if (location) {
      const city = location.results[0].address_components[2].short_name;
      setFetchCityLoading(false);
      setOrigin(city)
    }

  };

  const error = () => {
    setFetchCityLoading(false);
    console.error("Fehler bei der Standordermittlung.");
  };

  // get user location

  const getUserLocation = () => {
    if (geolocation) {
      setFetchCityLoading(true);
      navigator.geolocation.getCurrentPosition(getCityName, error);
    }
  };

  // custom handle of origin field
  const handleOriginChange = (event) => {
    const value = event.target.value;
    setOrigin(value);
  };

  // whenever a waypoint is edited
  const handleWaypointChange = (i, event) => {
    let items = [...waypoints];
    items[i].value = event.target.value;
    setWaypoints(items);
  };

  // handle click for adding a new waypoint
  const handleAddWaypoint = () => {
    // copy array using spread operator
    // values = waypoints <- this only creates a reference (we don't want that!)
    // we want a clean copy

    if (waypoints.length >= 4) {
      return;
    }

    let items, penultimateItem;

    items = [...waypoints];
    items.push({ value: "", label: "Ziel" });
    // We have more than one waypoint, so we want to change the label to "Zwischenziel"
    penultimateItem = { ...items[items.length - 2] };
    penultimateItem.label = "Zwischenziel";
    items[items.length - 2] = penultimateItem;
    setWaypoints(items);
    consoleHelper(waypoints);
  };

  // handle click for removing a new waypoint
  const handleRemoveWaypoint = () => {
    let items, lastItem;

    items = [...waypoints];

    if (items.length > 1) {
      items.splice(-1, 1);
      lastItem = { ...items[items.length - 1] };
      lastItem.label = "Ziel";
      items[items.length - 1] = lastItem;

      setWaypoints(items);
    }
  };

  return (
    <>
      <FormControl>

        <FormLabel htmlFor="origin">Start</FormLabel>
        <InputGroup>
          {fetchCityLoading && <InputLeftElement
            pointerEvents="none"
            children={<Spinner size="sm" color="blue.500" />}
          />}

          <Input
            name="origin"
            type="text"
            value={origin}
            onChange={(e) => handleOriginChange(e)}
          ></Input>
          <InputRightElement
            color="gray.400"
            size="24px"
            cursor="pointer"
            name="Standort ermitteln"
            onClick={getUserLocation}
          >
            <BiCurrentLocation />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      {/* Render additional waypoint  */}
      {waypoints.map((waypoint, idx) => {
        return (
          <FormControl key={`waypoint-${idx}`}>
            <FormLabel htmlFor={`waypoint-${idx}`}>
              {waypoints[idx].label === "Zwischenziel" && "↓ "}
              {waypoints[idx].label}
            </FormLabel>
            <Input
              name={`waypoint-${idx}`}
              type="text"
              value={waypoint.value || ""}
              onChange={(e) => handleWaypointChange(idx, e)}
            ></Input>
          </FormControl>
        );
      })}

      <ButtonGroup mb={4} spacing="1">
        <IconButton
          colorScheme="blue"
          aria-label="Add"
          icon={<BiPlus />}
          name=""
          onClick={handleAddWaypoint}
        />
        {waypoints.length >= 2 &&
          <IconButton
            colorScheme="gray"
            aria-label="Remove"
            icon={<BiMinus />}
            onClick={handleRemoveWaypoint}
          />
        }
      </ButtonGroup>
    </>
  );
};

export default Directions;
