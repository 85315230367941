import React from "react";
import { useColorMode, Flex, Box } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

const blue = "blue.200";
const yellow = "yellow.300";

function useThemeMeta() {
  const { colorMode, toggleColorMode } = useColorMode();

  const colMode = colorMode === "light" ? "light" : "dark";

  const switchLabel =
    colMode === "light" ? "Dark Theme aktivieren" : "Light Theme aktivieren";

  const colorModeIcon = { light: FaMoon, dark: FaSun };

  return {
    colMode,
    switchLabel,
    toggleColorMode,
    colorModeIcon,
  };
}

export const ColorModeSwitcher = (props) => {
  const {
    colMode,
    toggleColorMode,
    colorModeIcon,
    switchLabel,
  } = useThemeMeta();

  return (
    <Flex cursor="pointer" d="inline-flex" {...props}>
      <Box
        fontSize="1.5rem"
        as={colorModeIcon[colMode]}
        color={colMode === "light" ? yellow : blue}
        data-testid="theme-switch-sun"
        onClick={toggleColorMode}
        aria-label={switchLabel}
      />
    </Flex>
  );
};
